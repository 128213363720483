<template>
    <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>important_devices</md-icon>
                    </div>
                    <h4 class="title"> Informações do Plano</h4>
                </md-card-header>
                <md-card-content id="contentPlan">
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                            <label><b>Nome do plano</b>: {{dataPlan.name}}</label>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                            <label><b>Valor mensal do plano</b>: {{formatMoneyReal(dataPlan.amount)}}</label>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                            <label><b>Funcionalidades do plano</b></label>:<br>
                            <div class="md-layout funcionalidades">
                                <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
                                    v-for="(item, key) in servicesPLan" :key="key">
                                    <span v-if="key%2===0">{{item.service_name}}</span>
                                    <span v-if="key%2===1">{{item.service_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-15 md-large-size-15 md-xlarge-size-15">
                            <md-button class="md-success md-block" @click="Payment()">Atualizar pra este plano</md-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-15 md-large-size-15 md-xlarge-size-15">
                            <md-button class="md-danger md-block" @click="$router.go(-1)">Voltar</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>
<script>
    import {
        SlideYDownTransition
    } from 'vue2-transitions'
    import cardData from '../../../utils/events/finishCadCard.js'
    import services from '../../../routes/services.js'
    import models from '../../../routes/models.js'
    import axios from 'axios'
    import Swal from 'sweetalert2';
    const md5 = require('md5');
    export default {
        components: {
            SlideYDownTransition
        },
        data() {
            return {
                key: JSON.parse(localStorage.getItem('radio-default')).key,
                dataPlan: JSON.parse(sessionStorage.getItem('plans')),
                servicesPLan: JSON.parse(sessionStorage.getItem('services-plan')),
                minHeightInfoPlan: '',
                card_number: '',
                card_holder_name: '',
                card_expiration_month: '',
                card_expiration_year: '',
                card_cvv: '',
                card_cpf_cnpj: '',
                cloudURL: '',

                touched: {
                    card_number: false,
                    card_holder_name: false,
                    card_expiration_month: false,
                    card_nucard_expiration_yearmber: false,
                    card_cvv: false,
                    card_cpf_cnpj: false
                },

                modelValidations: {
                    card_number: {
                        required: true,
                        min: 16
                    },
                    card_holder_name: {
                        required: true,
                        min: 5
                    },
                    card_expiration_month: {
                        required: true,
                        min: 2
                    },
                    card_expiration_year: {
                        required: true,
                        min: 4
                    },
                    card_cvv: {
                        required: true,
                        min: 3
                    },
                    card_cpf_cnpj: {
                        required: true,
                        min: 11
                    }
                }
            }
        },
        methods: {
            Payment(){
                this.$router.push("payment")
            },
            getError(fieldName) {
                return this.errors.first(fieldName)
            },
            validate() {
                return this.$validator.validateAll().then(res => {
                    this.$emit('on-validated', res)
                    return res
                })
            },
            getDataCard() {
                let card = {}
                card.card_holder_name = this.card_holder_name
                card.card_expiration_date = this.card_expiration_month + '/' + this.card_expiration_year
                card.card_number = this.card_number
                card.card_cvv = this.card_cvv

                const cardValidations = pagarme.validate({
                    card: card
                })

                if (!cardValidations.card.card_number) {
                    let txt = "Número do cartão inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_holder_name) {
                    let txt = "Nome do portador inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_expiration_month) {
                    let txt = "Mês de expiração inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_expiration_year) {
                    let txt = "Ano de expiração inválido."
                    this.getSnackBar(txt)
                }
                if (!cardValidations.card.card_cvv) {
                    let txt = "Código de segurança inválido."
                    this.getSnackBar(txt)
                }

                const planId = JSON.parse(sessionStorage.getItem('plans')).id
                const str = this.card_expiration_year.substring(2, 4)
                const email = JSON.parse(localStorage.getItem('user')).email
                const customerName = JSON.parse(localStorage.getItem('user')).name + ' ' + JSON.parse(localStorage.getItem(
                    'user')).lastname
                const formData = {
                    api_key: 'ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S',
                    plan_id: planId,
                    card_number: card.card_number,
                    card_holder_name: card.card_holder_name.toUpperCase(),
                    card_expiration_date: this.card_expiration_month + str,
                    card_cvv: card.card_cvv,
                    customer: {
                        name: customerName,
                        email: email,
                        // phone: {
                        //   number: JSON.parse(sessionStorage.getItem('user')).phone
                        // },
                        document_number: this.card_cpf_cnpj
                    }
                }

                Swal.fire({
                    title: "Aguarde",
                    html: "Estamos processando todas as informações...",
                    type: "warning",
                    onOpen: () => {
                        Swal.showLoading()
                        axios.post("https://api.pagar.me/1/subscriptions", formData).then((resp) => {
                            models().put("users/upgradePlan", {
                                "email": email,
                                "pagarmeId": resp.data.id,
                                "key": this.key,
                                "profile_id": 228,
                            }).then(() => {
                                Swal.fire({
                                    title: "Pronto!",
                                    html: `Seu plano foi atualizado com sucesso! Em breve você receberá uma confirmação no email registrado: <b>${JSON.parse(localStorage.getItem('user')).email}</b>`,
                                    type: "success"
                                })
                                localStorage.clear()
                                sessionStorage.clear()
                                this.$router.push('/login')
                            })
                        })
                    }
                })
            },
            getSnackBar(txt) {
                let x = document.getElementById("snackbar2");
                x.innerHTML = txt;
                x.className = "show";

                setTimeout(function () {
                    x.className = x.className.replace("show", "");
                }.bind(), 3000);
            },
            formatMoneyReal(money) {
                let rest = money.toString();
                let pointA = 0;
                let pointB = 0;
                let sumPoints = 0;
                let format = 0;

                pointA = rest.slice(0,3);
                pointB = rest.slice(3);

                sumPoints = pointA + "." + pointB;
                format = parseInt(sumPoints).toFixed(2).split('.');
                format[0] = "R$" + format[0].split(/(?=(?:...)*$)/).join('.');
                return format.join(',');
            }
        },
        mounted() {
            this.minHeightInfoPlan = "min-height:" + document.getElementById("contentPlan").offsetHeight + "px"
        }
    }

</script>
<style>
    .funcionalidades {
        margin: 0;
        padding: 0 0 0 0;
        margin-left: -13px;
    }

    #snackbar2 {
        visibility: hidden;
        /* Hidden by default. Visible on click */
        min-width: 250px;
        /* Set a default minimum width */
        margin-left: -125px;
        /* Divide value of min-width by 2 */
        background-color: #503;
        /* Black background color */
        color: #fff;
        /* White text color */
        text-align: center;
        /* Centered text */
        border-radius: 2px;
        /* Rounded borders */
        padding: 16px;
        /* Padding */
        position: fixed;
        /* Sit on top of the screen */
        z-index: 1;
        /* Add a z-index if needed */
        left: 50%;
        /* Center the snackbar */
        bottom: 30px;
        /* 30px from the bottom */
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */

    #snackbar2.show {
        visibility: visible;
        /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    /* Animations to fade the snackbar in and out */

    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

</style>
